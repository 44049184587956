export const openingHoursData = [
    {
        day: "Monday", 
        arr: [{
            "start": "12:00 am",
            "end": "12:00 am"
        }], 
        open_close: 1, 
        shortDay: "Mon"
    },
    {
        day: "Tuesday", 
        arr: [{
            "start": "12:00 am",
            "end": "12:00 am"
        }], 
        open_close: 1, 
        shortDay: "Tue"
    },
    {
        day: "Wednesday", 
        arr: [{
            "start": "12:00 am",
            "end": "12:00 am"
        }], 
        open_close: 1, 
        shortDay: "Wed"
    },
    {
        day: "Thursday", arr: [{
            "start": "12:00 am",
            "end": "12:00 am"
        }], open_close: 1, shortDay: "Thur"
    },
    {
        day: "Friday", arr: [{
            "start": "12:00 am",
            "end": "12:00 am"
        }], open_close: 1, shortDay: "Fri"
    },
    { day: "Saturday", arr: [{ reason: "Unavailable" }], open_close: 0, shortDay: "Sat" },
    { day: "Sunday", arr: [{ reason: "Unavailable" }], open_close: 0, shortDay: "Sun" },
]
