import { createSlice } from "@reduxjs/toolkit";
import {
  deleteProperty,
  deleteService,
  getAllManageProperties,
  getAllUsers,
  getProperty,
  getService,
  getServiceList,
  getUsers,
  imagesaveAction,
  updateProperty,
  updateService,
} from "../actions/adminActions";
import { toast } from "react-hot-toast";

const initialState = {
  allManageProperties: [],
  totalManageProperties: null,
  userDetails: null,
  serviceDetails:null,
  allManageServiceProperties: [],
  totalManageServiceProperties: null,
};

export const managePropertySlice = createSlice({
  name: "managePropertySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllManageProperties.fulfilled, (state, { payload }) => {
        console.log(payload,"payload");
        if (payload?.success) {
          state.allManageProperties = payload.message;
          state.totalManageProperties = payload.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getProperty.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.userDetails = payload.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(updateProperty.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.userDetails = state.payload?.data;
          toast.success(payload?.message);
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(deleteProperty.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.allManageProperties = state.allManageProperties?.filter((item)=> item?._id != payload?.data?._id);
          toast.success(payload?.message);
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getServiceList.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.allManageServiceProperties = payload.data.service;
          state.totalManageServiceProperties = payload.data.totalServices;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getService.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.serviceDetails = payload.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(updateService.fulfilled, (state, { payload }) => {
        console.log(payload,"payload");
        if (payload?.success) {
          state.serviceDetails = state.payload?.data;
          toast.success(payload?.message);
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(deleteService.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.allManageServiceProperties = state.allManageServiceProperties?.filter((item)=> item?._id != payload?.data?._id);
          toast.success(payload?.message);
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      
  },
});

export default managePropertySlice.reducer;
