import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import { dashboard } from "../actions/adminActions";

const initialState = {
  dashboard: null,
};

export const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(dashboard.fulfilled, (state, { payload }) => {
      if (payload.success) {
        state.dashboard = payload.data;
      } else if (!payload.success) {
        toast.error(payload?.message);
      }
    });
  },
});

export default dashboardSlice.reducer;
